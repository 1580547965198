const ProgressBar = ({ step }) => {
  const totalSteps = 6;
  const progress = (step / totalSteps) * 100;

  return (
    <div className="-w-full -my-2">
      <progress
        className="-du-progress -du-progress-success -bg-gray-200 -w-full"
        value={progress}
        max="100"
      ></progress>
      <p className=" -text-md -font-medium -text-gray-700 -text-center -mt-2 ">{`Step ${step} of ${totalSteps}`}</p>
    </div>
  );
};

export default ProgressBar;
