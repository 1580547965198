import { Field, Formik, Form } from "formik";

const Step5 = ({ next, back, data, setFormData }) => {
  return (
    <Formik
      initialValues={{
        patientSatisfaction: data.patientSatisfaction || "",
        patientWaitTimes: data.patientWaitTimes || "",
        patientRetention: data.patientRetention || "",
      }}
      onSubmit={(values) => {
        next(values);
      }}
    >
      {({ values, handleChange }) => (
        <Form className="-space-y-2">
          <h2 className="-text-xl -font-bold -text-gray-800 -text-center">
            Patient Experience Metrics
          </h2>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">
                Patient Satisfaction Scores
              </span>
            </div>
            <Field
              as="select"
              name="patientSatisfaction"
              className="-du-select -du-select-bordered -du-select-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, patientSatisfaction: e.target.value });
              }}
            >
              <option value="">Select...</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </Field>
          </div>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Average Patient Wait Times</span>
            </div>
            <Field
              as="select"
              name="patientWaitTimes"
              className="-du-select -du-select-bordered -du-select-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, patientWaitTimes: e.target.value });
              }}
            >
              <option value="">Select...</option>
              <option value="quick">Quick</option>
              <option value="medium">Medium</option>
              <option value="slow">Slow</option>
            </Field>
          </div>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700 ">
              <span className="-du-label-text">Patient Retention Rates</span>
            </div>
            <Field
              as="select"
              name="patientRetention"
              className="-du-select -du-select-bordered -du-select-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, patientRetention: e.target.value });
              }}
            >
              <option value="">Select...</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </Field>
          </div>

          <div className="-flex -justify-between -pt-4">
            <button
              type="button"
              onClick={back}
              className="-du-btn -du-btn-secondary -text-white -du-btn-sm"
            >
              PREV
            </button>

            <button
              type="submit"
              className="-du-btn -du-btn-success -text-white -du-btn-sm"
            >
              NEXT
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step5;
