import { Field, Formik, Form } from "formik";

const Step1 = ({ next, back, data, setFormData }) => {
  return (
    <Formik
      initialValues={{
        staffNumbers: data.staffNumbers,
        visits: data.visits,
        procedures: data.procedures,
        automationStatus: data.automationStatus,
      }}
      onSubmit={(values) => {
        next(values);
      }}
    >
      {({ values, handleChange }) => (
        <Form className="-space-y-2">
          <h2 className="-text-xl -font-bold -text-gray-800 -text-center">
            Operational Data
          </h2>
          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Staff Numbers</span>
            </div>

            <Field
              as="textarea"
              name="staffNumbers"
              className="-du-textarea -du-textarea-bordered -du-textarea-primary- -max-w-full -resize-y -max-h-48"
              placeholder="e.g. 25 Full-time, 5 Part-time"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, staffNumbers: e.target.value });
              }}
            />
          </div>
          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Monthly Patient Visits</span>
            </div>
            <Field
              name="visits"
              type="number"
              placeholder="e.g. 500"
              className="-du-input -du-input-bordered -du-input-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, visits: e.target.value });
              }}
            />
          </div>
          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">
                Monthly Procedures/Services
              </span>
            </div>
            <Field
              name="procedures"
              type="number"
              placeholder="e.g. 200"
              className="-du-input -du-input-bordered -du-input-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, procedures: e.target.value });
              }}
            />
          </div>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Automation Status</span>
            </div>
            <Field
              as="select"
              name="automationStatus"
              className="-du-select -du-select-bordered -du-select-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, automationStatus: e.target.value });
              }}
            >
              <option value="">Select...</option>
              <option value="fully">Fully Automated</option>
              <option value="various">Various Processes Automated</option>
              <option value="partially">Partially Automated</option>
              <option value="not">Not Automated</option>
            </Field>
          </div>
          <div className="-flex -justify-between -pt-4">
            <button
              type="submit"
              onClick={back}
              className="-du-btn -du-btn-secondary -text-white -du-btn-sm"
            >
              PREV
            </button>

            <button
              type="submit"
              className="-du-btn -du-btn-success -text-white -du-btn-sm"
            >
              NEXT
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step1;
