import { Field, Formik, Form } from "formik";

const Step2 = ({ next, back, data, setFormData }) => {
  return (
    <Formik
      initialValues={{
        monthlyCosts: data.monthlyCosts,
        revenue: data.revenue,
        avgRevenuePerPatient: data.avgRevenuePerPatient,
        manualProcessCosts: data.manualProcessCosts,
      }}
      onSubmit={(values) => {
        next(values);
      }}
    >
      {({ values, handleChange }) => (
        <Form className="-space-y-2">
          <h2 className="-text-xl -font-bold -text-gray-800 -text-center">
            Financial Data
          </h2>

          {/* Monthly Costs */}
          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Monthly Costs $USD</span>
            </div>
            <Field
              name="monthlyCosts"
              type="number"
              className="-du-input -du-input-bordered -du-input-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, monthlyCosts: e.target.value });
              }}
            />
          </div>

          {/* Monthly Revenue */}
          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Monthly Revenue $USD</span>
            </div>
            <Field
              name="revenue"
              type="number"
              className="-du-input -du-input-bordered -du-input-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, revenue: e.target.value });
              }}
            />
          </div>

          {/* Average Revenue per Patient */}
          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">
                Average Revenue per Patient $USD
              </span>
            </div>
            <Field
              name="avgRevenuePerPatient"
              type="number"
              className="-du-input -du-input-bordered -du-input-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, avgRevenuePerPatient: e.target.value });
              }}
            />
          </div>

          {/* Costs for Manual Processes */}
          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">
                Costs for Manual Processes $USD
              </span>
            </div>
            <Field
              name="manualProcessCosts"
              type="number"
              className="-du-input -du-input-bordered -du-input-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, manualProcessCosts: e.target.value });
              }}
            />
          </div>

          {/* Navigation Buttons */}
          <div className="-flex -justify-between -pt-4">
            <button
              type="button"
              onClick={back}
              className="-du-btn -du-btn-secondary -text-white -du-btn-sm"
            >
              PREV
            </button>

            <button
              type="submit"
              className="-du-btn -du-btn-success -text-white -du-btn-sm"
            >
              NEXT
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step2;
