import { Field, Formik, Form } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
});

const Step0 = ({ next, data, setFormData }) => {
  return (
    <Formik
      initialValues={{
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        next(values);
      }}
    >
      {({ values, handleChange, errors, touched }) => (
        <Form className="-space-y-2">
          <h2 className="-text-xl -font-bold -text-gray-800 -text-center">
            Verify Email &amp; Name
          </h2>
          <div className="-du-form-control -w-full ">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Email</span>
            </div>
            <Field
              name="email"
              type="email"
              className="-du-input -du-input-bordered -du-input-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, email: e.target.value });
              }}
            />
            {touched.email && errors.email ? (
              <div className="-du-label -text-red-500">
                <div className="-du-label-text-alt">{errors.email}</div>
              </div>
            ) : null}

            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">First Name</span>
            </div>
            <Field
              name="firstName"
              type="text"
              className="-du-input -du-input-bordered -du-input-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, firstName: e.target.value });
              }}
            />
            {touched.firstName && errors.firstName ? (
              <div className="-du-label -text-red-500">
                <div className="-du-label-text-alt">{errors.firstName}</div>
              </div>
            ) : null}

            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Last Name</span>
            </div>
            <Field
              name="lastName"
              type="text"
              className="-du-input -du-input-bordered -du-input-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, lastName: e.target.value });
              }}
            />
            {touched.lastName && errors.lastName ? (
              <div className="-du-label -text-red-500">
                <div className="-du-label-text-alt">{errors.lastName}</div>
              </div>
            ) : null}
          </div>

          <div className="-flex -justify-end -pt-4">
            <button
              type="submit"
              className="-du-btn -du-btn-success -text-white -du-btn-sm"
            >
              NEXT
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step0;
