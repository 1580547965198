import { Field, Formik, Form } from "formik";

const Step6 = ({ next, back, data, setFormData }) => {
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const updatedNeeds = checked
      ? [...data.digitalNeeds, value]
      : data.digitalNeeds.filter((item) => item !== value);
    setFormData({ ...data, digitalNeeds: updatedNeeds });
  };

  return (
    <Formik
      initialValues={{
        objectives: data.objectives || "",
        digitalNeeds: data.digitalNeeds || [],
      }}
      onSubmit={(values) => {
        next(values);
      }}
    >
      {({ values, handleChange }) => (
        <Form className="-space-y-2">
          <h2 className="-text-xl -font-bold -text-gray-800 -text-center">
            Digital Transformation Goals
          </h2>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-lg -font-bold -text-gray-700 ">
              <span className="-du-label-text">Objectives</span>
            </div>
            <Field
              as="select"
              name="objectives"
              className="-du-select -du-select-bordered -du-select-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, objectives: e.target.value });
              }}
            >
              <option value="">Select...</option>
              <option value="efficiency">Improve Efficiency</option>
              <option value="reduce_costs">Reduce Operational Costs</option>
              <option value="patient_care">Enhance Patient Care</option>
              <option value="workflows">Optimize Workflows</option>
            </Field>
          </div>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700 ">
              <span className="-du-label-text">Digital Needs</span>
            </div>
            <div className="-grid -grid-cols-1 -divide-y -divide-gray-200">
              {[
                "Prescription Refill Automation",
                "Appointment Scheduling Automation",
                "Medical Record Summarization",
                "Chatbot Triage",
                "Remote Monitoring",
                "Virtual Care",
                "AI Scribe for Note-Taking",
                "Others",
              ].map((need, index) => (
                <div
                  key={index}
                  className={`-du-form-control ${
                    index % 2 === 0 ? "-bg-gray-100" : "bg-white"
                  }`}
                >
                  <label className="-du-label -cursor-pointer -flex -items-center -p-2">
                    <span className="-du-label-text">{need}</span>
                    <Field
                      type="checkbox"
                      value={need}
                      checked={data.digitalNeeds.includes(need)}
                      className="-du-checkbox -du-checkbox-success -bg-gray-300"
                      onChange={handleCheckboxChange}
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="-flex -justify-between -pt-4">
            <button
              type="button"
              onClick={back}
              className="-du-btn -du-btn-secondary -text-white -du-btn-sm"
            >
              PREV
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step6;
