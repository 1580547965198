import Particle from "../Particles";
import "../App.css";
import { useEffect, useState } from "react";
import { Carousel, Col, Modal, Row, Input, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import awardBg from "../assets/images/Winning-Awards-bg.jpg";
import ratingImg from "../assets/images/home-rating.png";
import award1 from "../assets/images/award-1.jpg";
import award2 from "../assets/images/award-2.jpg";
import award3 from "../assets/images/award-3.jpg";
import award4 from "../assets/images/award-4.jpg";
import award5 from "../assets/images/award-5.jpg";
import award6 from "../assets/images/award-6.jpg";
import processBg from "../assets/images/process-bg.png";
import clientsBg from "../assets/images/clients-heading.png";
import clients1 from "../assets/images/clients-1.png";
import clients2 from "../assets/images/clients-2.png";
import clients3 from "../assets/images/clients-3.png";
import clients4 from "../assets/images/clients-4.png";
import clients5 from "../assets/images/clients-5.png";
import clients6 from "../assets/images/clients-6.png";
import clients7 from "../assets/images/clients-7.png";
import clients8 from "../assets/images/clients-8.png";
import clients9 from "../assets/images/clients-9.png";
import clients10 from "../assets/images/clients-10.png";
import clients11 from "../assets/images/clients-11.png";
import clients12 from "../assets/images/clients-12.png";
import clients13 from "../assets/images/clients-13.png";
import clients14 from "../assets/images/clients-14.png";
import freeConsultBg from "../assets/images/free-consult-bg.jpg";
import clientSpeakBg from "../assets/images/client-speaks-bg.png";
import DrMicheal from "../assets/images/Dr Michael.jpeg";
import annaMarie from "../assets/images/Dr Anna Marie.jpeg";
import DrHanif from "../assets/images/Dr.-Hanif-Chatur-600x600.jpg";
import DrCarie from "../assets/images/Dr Carrie Heibron.jpeg";
import DrSohail from "../assets/images/Dr Sohail Khattak.jpeg";
import Rajini from "../assets/images/Ranjini Bhattacharya.jpeg";
import ourJourney from "../assets/images/our-journey.png";
import portfolioBg from "../assets/images/portfolio-bg.png";
import ourWork1 from "../assets/images/our-work-1.png";
import ourWork2 from "../assets/images/our-work-2.png";
import ourWork3 from "../assets/images/our-work-3.png";
import featuredBg from "../assets/images/Featured-bg.png";
import feature1 from "../assets/images/feature-1.png";
import feature2 from "../assets/images/feature-2.png";
import feature3 from "../assets/images/feature-3.png";
import feature4 from "../assets/images/feature-4.png";
import feature7 from "../assets/images/feature-7.png";
import feature8 from "../assets/images/feature-8.jpeg";
import feature9 from "../assets/images/feature-9.png";
import feature10 from "../assets/images/feature-10.png";
import feature11 from "../assets/images/feature-11.png";
import feature12 from "../assets/images/feature-12.png";
import feature13 from "../assets/images/feature-13.jpg";
import ChatLogic from "../components/ChatLogic";

function HomePage() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleCS1, setVisibleCS1] = useState(false);
  const [visibleCS2, setVisibleCS2] = useState(false);
  const [visibleCS3, setVisibleCS3] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [capVal, setCapVal] = useState(null);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
  };

  const handleCS1 = () => {
    setVisibleCS1(true);
  };
  const handleCS2 = () => {
    setVisibleCS2(true);
  };
  const handleCS3 = () => {
    setVisibleCS3(true);
  };
  const openTrainingPage = () => {
    window.open("https://markitech.ca/training/", "_blank");
  };

  const openPractice = () => {
    window.open("https://markitech.ca/pratice-optimization/", "_blank");
  };
  const openHealthcare = () => {
    window.open("https://markitech.ca/our-work-healthcare/", "_blank");
  };
  const openStaffAugmentation = () => {
    window.open("https://markitech.ca/staff-augmentation/", "_blank");
  };

  // useEffect(() => {
  //   // Wrap every letter in a span
  //   var textWrapper = document.querySelector(".ml6 .letters");
  //   textWrapper.innerHTML = textWrapper.textContent.replace(
  //     /\S/g,
  //     "<span class='letter'>$&</span>",
  //   );
  //   anime
  //     .timeline({ loop: true })
  //     .add({
  //       targets: ".ml6 .letter",
  //       translateY: ["1.1em", 0],
  //       translateZ: 0,
  //       duration: 750,
  //       delay: (el, i) => 50 * i,
  //     })
  //     .add({
  //       targets: ".ml6",
  //       opacity: 0,
  //       duration: 1000,
  //       easing: "easeOutExpo",
  //       delay: 1000,
  //     });
  // }, []);
  return (
    <>
      <div className="-flex -flex-col -min-h-[750px]">
        <ChatLogic />
      </div>

      <div className="App">
        <img className="award-bg-width" alt="awardBg" src={awardBg} />
        <Row>
          <Col className="align-left" xl={18} xs={24}>
            <h2 className="heading-color">Awards and Recognitions</h2>
            <p className="para-size">
              We excel at expediting innovation and adopting agile practices to
              develop mobile apps that are packed with features and loved by
              users. Our focus on user satisfaction, commitment to quality, and
              forward-thinking approach to mobile app development have been
              instrumental in earning us prestigious awards and recognition.
            </p>
            <a
              href="https://clutch.co/profile/markitech#reviews"
              target="_blank"
              className="review-btn"
            >
              Check Reviews
            </a>
          </Col>
          <Col xl={6} xs={24}>
            <img alt="ratingImg" src={ratingImg} />
          </Col>
        </Row>
        <Row className="pad-t-70">
          <Col xl={24} xs={0}>
            <Carousel arrows={true} slidesToShow={6} autoplay>
              <div className="flex-center">
                <img alt="award-1" src={award1} />
              </div>
              <div className="flex-center">
                <img alt="award-2" src={award2} />
              </div>
              <div className="flex-center">
                <img alt="award-3" src={award3} />
              </div>
              <div className="flex-center">
                <img alt="award-4" src={award4} />
              </div>
              <div className="flex-center">
                <img alt="award-5" src={award5} />
              </div>
              <div className="flex-center">
                <img alt="award-6" src={award6} />
              </div>
            </Carousel>
          </Col>
          <Col xl={0} xs={24}>
            <Carousel
              arrows={true}
              slidesToShow={1}
              autoplaySpeed={1000}
              autoplay
            >
              <div className="flex-center">
                <img alt="award-1" src={award1} />
              </div>
              <div className="flex-center">
                <img alt="award-2" src={award2} />
              </div>
              <div className="flex-center">
                <img alt="award-3" src={award3} />
              </div>
              <div className="flex-center">
                <img alt="award-4" src={award4} />
              </div>
              <div className="flex-center">
                <img alt="award-5" src={award5} />
              </div>
              <div className="flex-center">
                <img alt="award-6" src={award6} />
              </div>
            </Carousel>
          </Col>
        </Row>

        <Row className="pad-t-70 process-bg-blue">
          <Col xl={5} />
          <Col xl={7} xs={24}>
            <img className="award-bg-width" alt="processBg" src={processBg} />
            <h2 className="process-heading">
              Innovative AI Services for Healthcare
            </h2>
          </Col>
          <Col xl={7} xs={24}>
            <p className="process-para">
              We continuously upgrade our core competencies around the latest
              and greatest in technology with a strong contextual relation to
              the business. This ensures successful implementation and
              management of solutions for our customers. <br />
              Our solutions sustain future advancements in technology, thereby
              reducing the need to invest in IT re-engineering.
            </p>
          </Col>
          <Col xl={5} />
        </Row>
        <Row className="pad-t-70">
          <Col xl={4} xs={0} />
          <Col xl={16} xs={24}>
            <img className="award-bg-width" alt="clientsBg" src={clientsBg} />
            <h2 className="our-client-heading">Our Clients</h2>
          </Col>
          <Col xl={4} xs={0} />
        </Row>
        <Row className="pad-t-70 ">
          <Col xl={24} xs={0}>
            <img className="clients-img" alt="clients1" src={clients1} />
            <img className="clients-img" alt="clients2" src={clients2} />
            <img className="clients-img" alt="clients3" src={clients3} />
            <img className="clients-img" alt="clients4" src={clients4} />
            <img className="clients-img" alt="clients5" src={clients5} />
            <img className="clients-img" alt="clients6" src={clients6} />
            <img className="clients-img" alt="clients7" src={clients7} />
          </Col>
          <Col className="mb-60" xl={24} xs={0}>
            <img className="clients-img" alt="clients8" src={clients8} />
            <img className="clients-img" alt="clients9" src={clients9} />
            <img className="clients-img" alt="clients10" src={clients10} />
            <img className="clients-img" alt="clients11" src={clients11} />
            <img className="clients-img" alt="clients12" src={clients12} />
            <img className="clients-img" alt="clients13" src={clients13} />
            <img className="clients-img" alt="clients14" src={clients14} />
          </Col>
          <Col className="mb-60" xl={0} xs={24}>
            <Carousel
              arrows={true}
              slidesToShow={1}
              autoplaySpeed={1500}
              autoplay
            >
              <div className="flex-center">
                <img className="clients-img" alt="clients1" src={clients1} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients2" src={clients2} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients3" src={clients3} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients4" src={clients4} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients5" src={clients5} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients6" src={clients6} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients7" src={clients7} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients8" src={clients8} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients9" src={clients9} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients10" src={clients10} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients11" src={clients11} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients12" src={clients12} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients13" src={clients13} />
              </div>
              <div className="flex-center">
                <img className="clients-img" alt="clients14" src={clients14} />
              </div>
            </Carousel>
          </Col>
          <Col xl={24}>
            <button className="cta-btn" onClick={openTrainingPage}>
              Unlock AI's Potential in Healthcare - Enroll Now for Free!
            </button>
          </Col>
        </Row>

        <Row className="justify-center">
          <img
            className="free-consult-bg-width"
            alt="freeConsultBg"
            src={freeConsultBg}
          />
          <Col className="flex-center" xl={24}>
            <h2 className="free-consult-heading">
              Custom Ai and Technology Solutions for your Needs
            </h2>
          </Col>
          <Col xl={24}>
            <a
              target="_blank"
              href="https://calendly.com/nauman-jaffar/30min?month=2024-09"
              className="free-consult-btn"
            >
              Free Consultation
            </a>
          </Col>
        </Row>
        <Row className="pad-t-70">
          <Col xl={4} xs={0} />
          <Col xl={16} xs={24}>
            <img
              className="award-bg-width"
              alt="clientSpeakBg"
              src={clientSpeakBg}
            />
            <h2 className="our-client-heading">Client Speaks</h2>
          </Col>
          <Col xl={4} xs={0} />
        </Row>
        <Row>
          <Col xl={3} xs={0} />
          <Col xl={18} xs={0}>
            <Carousel
              arrows={true}
              autoplay={true}
              autoplaySpeed={2000}
              slidesToShow={3}
            >
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={annaMarie}
                    alt="annaMarie"
                  />
                  <p className="testimonial-name">Anna Marie Vargas-Leveriza</p>
                  <p className="testimonial-title">
                    Director at Office of Child and Vourn Prorecrion and Safe
                    Environment in Roman Catholic Diocese of Phoenix
                  </p>
                  <p className="testimonial-para">
                    "Markitech revolutionizes our sessions, capturing nuances
                    effortlessly so I can remain present with my clients."
                  </p>
                  <p className="testimonial-date">May 2 2023</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={DrMicheal}
                    alt="DrMicheal"
                  />
                  <p className="testimonial-name">Dr. Michael Best​</p>
                  <p className="testimonial-title">
                    Assistant Professor at Department of Psychology and
                    Psychological Clinical Science in University of Toronto
                    Scarborough
                  </p>
                  <p className="testimonial-para">
                    "As a psychologist and professor, it's crucial for my team
                    to have access to tools that simplify and enhance our
                    research and clinical practice. Cliniscripts has been a
                    game-changer for us. Its intuitive design and innovative
                    features have significantly improved the efficiency and
                    accuracy of our documentation."
                  </p>
                  <p className="testimonial-date">June 12 2023</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={DrHanif}
                    alt="DrHanif"
                  />
                  <p className="testimonial-name">Dr. Hanif Chatur​</p>
                  <p className="testimonial-title">CEO at Beyond ADHD​</p>
                  <p className="testimonial-para">
                    "Efficiently empowering mental health practitioners with
                    precise and personalized Al-generated session notes,
                    revolutionizing therapeutic documentation."
                  </p>
                  <p className="testimonial-date">January 16 2024</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={DrCarie}
                    alt="DrCarie"
                  />
                  <p className="testimonial-name">
                    Dr. Carrie Heilbron, PhD., C.Psych. (she, her)
                  </p>
                  <p className="testimonial-title">
                    Chief Psychologist, Rehabilitation and Clinical Services
                    March of Dimes Canada
                  </p>
                  <p className="testimonial-para">
                    "Managing patient notes and documentation is time-consuming
                    and cumbersome, ....an automated system with easy templates
                    and secure access would significantly ease the
                    administrative burden."
                  </p>
                  <p className="testimonial-date">July 16 2024</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={DrSohail}
                    alt="DrSohail"
                  />
                  <p className="testimonial-name">Dr. Sohail Khattak​</p>
                  <p className="testimonial-title">
                    Paediatrician & Clinical Pharmacologist & Toxicologist Kids
                    Clinic
                  </p>
                  <p className="testimonial-para">
                    "CiniScripts has been an excellent experience. In particular
                    the remarkable service of the team is what makes this one of
                    the best AI Note taking solutions available."
                  </p>
                  <p className="testimonial-date">Aug 2 2024</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img className="testimonial-img" src={Rajini} alt="Rajini" />
                  <p className="testimonial-name"> Ranjini Bhattacharya​</p>
                  <p className="testimonial-title">
                    Office Manager/Human Resources <br /> Integrate Health
                    Services <br /> Kids Clinic
                  </p>
                  <p className="testimonial-para">
                    "CliniScripts has brought a new dimension of medical record
                    keeping for our clinic and demonstrates how important it is
                    to integrate new technology to better manage our schedule"
                  </p>
                  <p className="testimonial-date">Aug 2 2024</p>
                </div>
              </div>
            </Carousel>
          </Col>
          <Col xl={3} xs={0} />
          <Col xl={0} xs={24}>
            <Carousel
              arrows={true}
              autoplay={true}
              autoplaySpeed={2000}
              slidesToShow={1}
            >
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={annaMarie}
                    alt="annaMarie"
                  />
                  <p className="testimonial-name">Anna Marie Vargas-Leveriza</p>
                  <p className="testimonial-title">
                    Director at Office of Child and Vourn Prorecrion and Safe
                    Environment in Roman Catholic Diocese of Phoenix
                  </p>
                  <p className="testimonial-para">
                    "Markitech revolutionizes our sessions, capturing nuances
                    effortlessly so I can remain present with my clients."
                  </p>
                  <p className="testimonial-date">May 2 2023</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={DrMicheal}
                    alt="DrMicheal"
                  />
                  <p className="testimonial-name">Dr. Michael Best​</p>
                  <p className="testimonial-title">
                    Assistant Professor at Department of Psychology and
                    Psychological Clinical Science in University of Toronto
                    Scarborough
                  </p>
                  <p className="testimonial-para">
                    "As a psychologist and professor, it's crucial for my team
                    to have access to tools that simplify and enhance our
                    research and clinical practice. Cliniscripts has been a
                    game-changer for us. Its intuitive design and innovative
                    features have significantly improved the efficiency and
                    accuracy of our documentation."
                  </p>
                  <p className="testimonial-date">June 12 2023</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={DrHanif}
                    alt="DrHanif"
                  />
                  <p className="testimonial-name">Dr. Hanif Chatur​</p>
                  <p className="testimonial-title">CEO at Beyond ADHD​</p>
                  <p className="testimonial-para">
                    "Efficiently empowering mental health practitioners with
                    precise and personalized Al-generated session notes,
                    revolutionizing therapeutic documentation."
                  </p>
                  <p className="testimonial-date">January 16 2024</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={DrCarie}
                    alt="DrCarie"
                  />
                  <p className="testimonial-name">
                    Dr. Carrie Heilbron, PhD., C.Psych. (she, her)
                  </p>
                  <p className="testimonial-title">
                    Chief Psychologist, Rehabilitation and Clinical Services
                    March of Dimes Canada
                  </p>
                  <p className="testimonial-para">
                    "Managing patient notes and documentation is time-consuming
                    and cumbersome, ....an automated system with easy templates
                    and secure access would significantly ease the
                    administrative burden."
                  </p>
                  <p className="testimonial-date">July 16 2024</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img
                    className="testimonial-img"
                    src={DrSohail}
                    alt="DrSohail"
                  />
                  <p className="testimonial-name">Dr. Sohail Khattak​</p>
                  <p className="testimonial-title">
                    Paediatrician & Clinical Pharmacologist & Toxicologist Kids
                    Clinic
                  </p>
                  <p className="testimonial-para">
                    "CliniScripts has been an excellent experience. In
                    particular the remarkable service of the team is what makes
                    this one of the best AI Note taking solutions available."
                  </p>
                  <p className="testimonial-date">Aug 2 2024</p>
                </div>
              </div>
              <div className="flex-center">
                <div className="testimonial-box">
                  <img className="testimonial-img" src={Rajini} alt="Rajini" />
                  <p className="testimonial-name"> Ranjini Bhattacharya​</p>
                  <p className="testimonial-title">
                    Office Manager/Human Resources <br /> Integrate Health
                    Services <br /> Kids Clinic
                  </p>
                  <p className="testimonial-para">
                    "CliniScripts has brought a new dimension of medical record
                    keeping for our clinic and demonstrates how important it is
                    to integrate new technology to better manage our schedule"
                  </p>
                  <p className="testimonial-date">Aug 2 2024</p>
                </div>
              </div>
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mt-30" xl={24} xs={24}>
            <button className="cta-btn" onClick={openStaffAugmentation}>
              Explore our Expert Staff Augmentation Services for Tech and
              Healthcare
            </button>
          </Col>
          <Col xl={3} xs={0} />
          <Col className="mb-60" xl={18} xs={24}>
            <h2 className="our-client-heading">Our Journey</h2>
            <img className="width-100" alt="ourJourney" src={ourJourney} />
          </Col>
          <Col xl={3} xs={0} />
          <Col xl={24} xs={24}>
            <button className="cta-btn" onClick={openPractice}>
              Optimize Clinic Operations with AI Solutions - Get Started!
            </button>
          </Col>
        </Row>
        <Row className="pad-t-70">
          <Col xl={4} xs={0} />
          <Col xl={16} xs={24}>
            <img
              className="award-bg-width"
              alt="portfolioBg"
              src={portfolioBg}
            />
            <h2 className="our-client-heading">Our Work</h2>
          </Col>
          <Col xl={4} xs={0} />
        </Row>
        <Row className="pad-t-70">
          <Col className="our-work-bg-color" xl={24} xs={24}>
            <Row>
              <Col className="flex-align-center" xl={15}>
                <div>
                  <h2 className="our-work-title">
                    FORECASTING AND SCHEDULING CASE STUDY
                  </h2>
                  <p className="our-work-para">
                    Drawing upon its extensive proficiency in the healthcare
                    sector and artificial intelligence technologies,
                    MarkiTech.AI initiated a comprehensive endeavor aimed at
                    addressing staff scheduling challenges.
                  </p>
                  <button onClick={handleCS1} className="review-btn">
                    Download Case Study
                  </button>
                </div>
              </Col>
              <Col xl={9}>
                <img
                  className="width-100 m-t-15"
                  alt="ourWork1"
                  src={ourWork1}
                />
              </Col>
            </Row>
          </Col>
          <Col className="our-work-bg-color-2" xl={24} xs={24}>
            <Row>
              <Col className="flex-align-center" xl={15}>
                <div>
                  <h2 className="our-work-title-2">
                    PILOT: AUTOMATION USING VIDEO ANALYTICS
                  </h2>
                  <p className="our-work-para-2">
                    In just 30 days, MarkiTech.AI developed an Android app that
                    harnessed the power of machine learning to address TELUS'
                    challenges. This app utilized the device's camera to
                    categorize objects accurately, revolutionizing tasks across
                    sectors like agriculture and manufacturing.
                  </p>
                  <button onClick={handleCS2} className="review-btn">
                    Download Case Study
                  </button>
                </div>
              </Col>
              <Col xl={9}>
                <img
                  className="width-100 m-t-15"
                  alt="ourWork2"
                  src={ourWork2}
                />
              </Col>
            </Row>
          </Col>
          <Col className="our-work-bg-color mb-60" xl={24} xs={24}>
            <Row>
              <Col className="flex-align-center" xl={15}>
                <div>
                  <h2 className="our-work-title">DENTAL PRACTICE CASE STUDY</h2>
                  <p className="our-work-para">
                    Leveraging its specialization in healthcare and AI
                    technologies, MarkiTech.AI embarked on a comprehensive
                    solution for dental clinics.
                  </p>
                  <button onClick={handleCS3} className="review-btn">
                    Download Case Study
                  </button>
                </div>
              </Col>
              <Col xl={9}>
                <img
                  className="width-100 m-t-15"
                  alt="ourWork3"
                  src={ourWork3}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={24} xs={24}>
            <button className="cta-btn" onClick={openHealthcare}>
              Review our Healthcare Projects Now!
            </button>
          </Col>
        </Row>
        <Row>
          <Col xl={4} xs={0} />
          <Col xl={16} xs={24}>
            <img className="award-bg-width" alt="featuredBg" src={featuredBg} />
            <h2 className="our-client-heading">We're Featured In</h2>
          </Col>
          <Col xl={4} xs={0} />
        </Row>
        <Row className="pad-t-70">
          <Col xl={24} xs={0}>
            <Carousel arrows={true} slidesToShow={5}>
              <div className="flex-center">
                <img className="width-60" alt="feature1" src={feature1} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature2" src={feature2} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature3" src={feature3} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature4" src={feature4} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature7" src={feature7} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature8" src={feature8} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature9" src={feature9} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature10" src={feature10} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature11" src={feature11} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature12" src={feature12} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature13" src={feature13} />
              </div>
            </Carousel>
          </Col>
          <Col xl={0} xs={24}>
            <Carousel arrows={true} slidesToShow={1} autoplay>
              <div className="flex-center">
                <img className="width-60" alt="feature1" src={feature1} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature2" src={feature2} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature3" src={feature3} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature4" src={feature4} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature7" src={feature7} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature8" src={feature8} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature9" src={feature9} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature10" src={feature10} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature11" src={feature11} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature12" src={feature12} />
              </div>
              <div className="flex-center">
                <img className="width-60" alt="feature13" src={feature13} />
              </div>
            </Carousel>
          </Col>
        </Row>
        <Modal
          open={visibleCS1}
          footer={null}
          onCancel={() => setVisibleCS1(false)}
          className="-top-[60vh] sm:-top-[68vh]"
        >
          <form
            target="_blank"
            action="https://forms.zohopublic.com/markitech1/form/CaseStudyHomepageMarkitech/formperma/GLES4tiHqqC-J2F3M_wyPGCXlzAyZHH6p1YKnV3u8IY/htmlRecords/submit"
            name="form"
            id="form"
            method="POST"
            acceptCharset="UTF-8"
            encType="multipart/form-data"
          >
            <p>Name/Company:</p>
            <Input name="SingleLine" placeholder="Name/Company" />
            <p>Email:</p>
            <Input name="Email" placeholder="Email" />
            <div className="submit-container-cs">
              <button
                className="submit-btn-cs"
                type="submit"
                onClick={() => setVisibleCS1(false)}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          open={visibleCS2}
          footer={null}
          onCancel={() => setVisibleCS2(false)}
          className="-top-[73vh] sm:-top-[74vh]"
        >
          <form
            target="_blank"
            action="https://forms.zohopublic.com/markitech1/form/CaseStudyTelusMarkitech1/formperma/ei0lF2K22QSVl3A-0TqQOo2xF8veOJ5q1ts6hU41Td0/htmlRecords/submit"
            name="form"
            id="form"
            method="POST"
            acceptCharset="UTF-8"
            encType="multipart/form-data"
          >
            <p>Name/Company:</p>
            <Input name="SingleLine" placeholder="Name/Company" />
            <p>Email:</p>
            <Input name="Email" placeholder="Email" />
            <div className="submit-container-cs">
              <button
                className="submit-btn-cs"
                type="submit"
                onClick={() => setVisibleCS2(false)}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          open={visibleCS3}
          footer={null}
          onCancel={() => setVisibleCS3(false)}
          className="-top-[83vh] sm:-top-[81vh]"
        >
          <form
            target="_blank"
            action="https://forms.zohopublic.com/markitech1/form/CaseStudyDentalPracticeHomepageMarkitech/formperma/aYb5or6CH_tlklq-DyOr21LB1O0pAwpfST4Wfx7gjBM/htmlRecords/submit"
            name="form"
            id="form"
            method="POST"
            acceptCharset="UTF-8"
            encType="multipart/form-data"
          >
            <p>Name/Company:</p>
            <Input name="SingleLine" placeholder="Name/Company" />
            <p>Email:</p>
            <Input name="Email" placeholder="Email" />
            <div className="submit-container-cs">
              <button
                className="submit-btn-cs"
                type="submit"
                onClick={() => setVisibleCS3(false)}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          title="Your Input"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>You typed: {inputValue}</p>
          <ReCAPTCHA
            sitekey="6LcKBisqAAAAANxThEPvdfY0jusIni9odxNiFr_f"
            onChange={(val) => setCapVal(val)}
          />
        </Modal>
      </div>
    </>
  );
}

export default HomePage;
