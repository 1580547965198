import { Field, Formik, Form } from "formik";

const Step4 = ({ next, back, data, setFormData }) => {
  return (
    <Formik
      initialValues={{
        ehrSystems: data.ehrSystems || "",
        automationTools: data.automationTools || "",
        techGaps: data.techGaps || "",
      }}
      onSubmit={(values) => {
        next(values);
      }}
    >
      {({ values, handleChange }) => (
        <Form className="-space-y-2">
          <h2 className="-text-xl -font-bold -text-gray-800 -text-center">
            Current Technology Stack
          </h2>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">
                Electronic Health Record (EHR) Systems
              </span>
            </div>
            <Field
              as="textarea"
              name="ehrSystems"
              className="-du-textarea -du-textarea-bordered -du-textarea-primary -resize-y -max-w-full -max-h-48"
              placeholder="Identify current software and integration capabilities"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, ehrSystems: e.target.value });
              }}
            />
          </div>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Automation Tools in Use</span>
            </div>
            <Field
              as="select"
              name="automationTools"
              className="-du-select -du-select-bordered -du-select-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, automationTools: e.target.value });
              }}
            >
              <option value="">Select...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="partially">Partially</option>
            </Field>
          </div>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Gaps in Current Technology</span>
            </div>
            <Field
              as="select"
              name="techGaps"
              className="-du-select -du-select-bordered -du-select-primary"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, techGaps: e.target.value });
              }}
            >
              <option value="">Select...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="partially">Partially</option>
            </Field>
          </div>

          <div className="-flex -justify-between -pt-4">
            <button
              type="button"
              onClick={back}
              className="-du-btn -du-btn-secondary -text-white -du-btn-sm"
            >
              PREV
            </button>

            <button
              type="submit"
              className="-du-btn -du-btn-success -text-white -du-btn-sm"
            >
              NEXT
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step4;
