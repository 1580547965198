const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const call_bot = async (input, priority) => {
  const response = await fetch(`${BACKEND_URL}/api/chat`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userInput: input, priority: priority }),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch bot response");
  }

  return response.json();
};
