import ChatLogic from "../components/ChatLogic";

function TestPage() {
  return (
    <div className="-min-h-screen">
      <ChatLogic />
    </div>
  );
}

export default TestPage;
