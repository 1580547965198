import Step0 from "../components/form_steps/Step0";
import Step1 from "../components/form_steps/Step1";
import Step2 from "../components/form_steps/Step2";
import Step3 from "../components/form_steps/Step3";
import Step4 from "../components/form_steps/Step4";
import Step5 from "../components/form_steps/Step5";
import Step6 from "../components/form_steps/Step6";
import { useState } from "react";
import axios from "axios";
//import { Formik, Form } from "formik";
import ProgressBar from "../components/ProgressBar";

function FormPage() {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    staffNumbers: "",
    visits: "",
    procedures: "",
    automationStatus: "",
    monthlyCosts: "",
    revenue: "",
    avgRevenuePerPatient: "",
    manualProcessCosts: "",
    nonClinicalTasks: "",
    avgTimePerTask: "",
    interactionToAdminRatio: "",
    ehrSystems: "",
    automationTools: "",
    techGaps: "",
    patientSatisfaction: "",
    patientWaitTimes: "",
    patientRetention: "",
    objectives: "",
    digitalNeeds: [],
  });
  const [submitted, setSubmitted] = useState(false);
  const handleNext = (values) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setStep((prev) => prev + 1);
  };

  const handleBack = () => setStep((prev) => Math.max(prev - 1));

  const handleSubmit = async () => {
    // send form data to zoho api in server
    const API_URL = process.env.REACT_APP_BACKEND_URL;
    // const API_URL = "http://localhost:8080/";
    try {
      const zohoResponse = await axios.post(`${API_URL}/api/zoho-leads`, {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
      });

      if (zohoResponse.data.success) {
        setSubmitted(true);
        setStep(step + 1);
      } else {
        alert("Failed to add lead to Zoho CRM");
      }

      const openaiResponse = await axios.post(
        `${API_URL}/api/simulator`,
        formData,
      );

      if (openaiResponse.data.success) {
        console.log("OpenAI response generated succesfully.");
      } else {
        alert("Failed to generate OpenAI response");
      }
    } catch (error) {
      console.error("Error submitting data", error);
      alert("Failed to submit data");
    }
  };

  const pages = [
    <Step0 next={handleNext} data={formData} setFormData={setFormData} />,
    <Step1
      next={handleNext}
      back={handleBack}
      data={formData}
      setFormData={setFormData}
    />,
    <Step2
      next={handleNext}
      back={handleBack}
      data={formData}
      setFormData={setFormData}
    />,
    <Step3
      next={handleNext}
      back={handleBack}
      data={formData}
      setFormData={setFormData}
    />,
    <Step4
      next={handleNext}
      back={handleBack}
      data={formData}
      setFormData={setFormData}
    />,
    <Step5
      next={handleNext}
      back={handleBack}
      data={formData}
      setFormData={setFormData}
    />,
    <Step6
      next={handleNext}
      back={handleBack}
      data={formData}
      setFormData={setFormData}
    />,
  ];

  const confirmationPage = (
    <div className="">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="-h-12 -w-12 -text-white -bg-success -rounded-3xl"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <h1 className="-text-md -font-bold -text-left -text-gray-800">
        Thanks for submitting your form!
      </h1>
      <p className="-text-sm">You can now close the popup.</p>
    </div>
  );

  return (
    <div className="-bg-white -w-full">
      {!submitted && <ProgressBar step={step} />}
      {!submitted ? (
        <>
          <div className="-flex -flex-col -justify-center  -h-full">
            {pages[step]}
            {step === pages.length - 1 && (
              <button
                className="-du-btn -du-btn-success -text-white -text-lg -w-full -mt-2"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </>
      ) : (
        confirmationPage
      )}
    </div>
  );
}

export default FormPage;
