import { call_bot } from "../services/chatApi";
import { useState, useCallback, useEffect, useRef } from "react";
import "../App.css";
import Particle from "../Particles";
import { send_mail } from "../services/mailApi";
import { verify_token } from "../services/verifyToken";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

function ChatLogic() {
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chatResponse, setChatResponse] = useState("");
  const [priority, setPriority] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [refreshRecaptcha, setRefreshRecaptcha] = useState(false);
  const [lowerBound, setLowerBound] = useState(null);
  const [upperBound, setUpperBound] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [examples, setExamples] = useState([
    "I want to develop a mobile application which can scan blood reports using AI and detect any anomalities, native IOS and integration with Athena EMR",
    "I want to develop a mobile application that uses AI to drive customer support chatbots and streamline interactions",
    "I want to develop a mobile application that manages patient records securely and integrates seamlessly with healthcare systems",
  ]);

  const [currentExample, setCurrentExample] = useState(0);
  const [fade, setFade] = useState(true);
  const [isIframeVisible, setIframeVisible] = useState(true);
  const iframeRef = useRef(null);
  const [loadCount, setLoadCount] = useState(0);

  // Initial src for the Zoho form
  const initialIframeSrc =
    "https://forms.zohopublic.com/markitech1/form/EstimationToolForm/formperma/CwAjQGhH2yS0sZ71ElSrdCVDrZ83S9V3xSWX0O_Mo3Y";

  // Handler for the onLoad event of the iframe
  const handleIframeLoad = () => {
    setLoadCount((prevCount) => {
      const newCount = (typeof prevCount === "number" ? prevCount : 1) + 1;
      console.log(`Iframe load count: ${newCount}`);

      if (newCount === 2) {
        // Hide the iframe after the second load
        setIframeVisible(false);
      }
    });

    console.log("Iframe loaded or navigated");
    // Additional checks or logic can be added here if needed
  };
  const onChange = (e) => {
    setInput(e.target.value);
  };

  const handlePriority = (e) => {
    setPriority(e.target.value);
  };

  const handleInput = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    document.getElementById("my_modal_1").showModal();
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim()) {
      alert("Please enter an email address");
      return;
    }

    setIsLoading(true);
    document.getElementById("my_modal_1").close();
    try {
      //test recaptcha token first

      const verifyToken = await verify_token(token);
      const verifyResult = await verifyToken;
      if (!verifyResult.success) {
        alert("Failed to verify reCAPTCHA token. Please try again.");
        setRefreshRecaptcha(!refreshRecaptcha);
        return;
      }
      const response = await call_bot(input, priority);
      const parsedResponse = JSON.parse(response);
      setChatResponse(parsedResponse);
      let projectSubject = parsedResponse.projectOverview;
      console.log(projectSubject);
      console.log(email);

      const { lowerBound, upperBound } = calculateCostRange(
        parsedResponse.totalCost,
      );
      setLowerBound(lowerBound);
      setUpperBound(upperBound);

      const emailData = {
        email,
        chatResponse: parsedResponse,
        lowerBound: lowerBound,
        upperBound: upperBound,
      };

      const emailResponse = await send_mail(emailData);
    } catch (error) {
      console.error(error);
      setRefreshRecaptcha(!refreshRecaptcha);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !isLoading) {
      handleInput(e);
    }
  };

  const handleVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken],
  );

  const calculateCostRange = (totalCost) => {
    const cost = parseFloat(totalCost.replace(/[^0-9.-]+/g, ""));
    const lowerBound = Math.round(cost * 0.75);
    const upperBound = Math.round(cost * 1.25);
    console.log(cost, lowerBound, upperBound);
    return { lowerBound, upperBound };
  };

  useEffect(() => {
    if (chatResponse) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [chatResponse]);

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const exampleTimer = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentExample((prev) => (prev + 1) % examples.length);
        setFade(true);
      }, 500);
    }, 4000);

    return () => clearInterval(exampleTimer);
  }, [examples.length]);

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CLIENT_CAPTCHA}
      >
        <div className="particle-container ">
          <Particle />
          {isLoading && (
            <div className="-flex -justify-center -min-h-full">
              <span className="-loading -loading-spinner -w-36 -text-orange-400"></span>
            </div>
          )}

          {!chatResponse && !isLoading && (
            <div className="">
              <div className="-p-12 sm:-p-32">
                <h1 className="ml6">
                  <span className="text-wrapper">
                    <span className="letter">
                      Leading AI and Digital Health Solutions Provider
                    </span>
                  </span>

                  <div className="-flex -flex-col -w-full -z-20">
                    <span className="-text-orange-300 -text-lg -ml-1 -tracking-wider">
                      Step One: Choose Priority
                    </span>
                    <div className="-form-control -w-full -max-w-xs">
                      <label className="-label -cursor-pointer -flex -items-center -justify-between">
                        <span className="-label-text -text-lg -text-white">
                          Prioritize Time
                        </span>
                        <input
                          type="radio"
                          name="priority"
                          value="time"
                          className="-radio -bg-white checked:-bg-orange-500"
                          checked={priority === "time"}
                          onChange={handlePriority}
                        />
                      </label>
                    </div>
                    <div className="-form-control -w-full -max-w-xs">
                      <label className="-label -cursor-pointer -flex -items-center -justify-between">
                        <span className="-label-text -text-lg -text-white">
                          Prioritize Cost
                        </span>
                        <input
                          type="radio"
                          name="priority"
                          value="cost"
                          className="-radio -bg-white checked:-bg-orange-500"
                          checked={priority === "cost"}
                          onChange={handlePriority}
                        />
                      </label>
                    </div>
                  </div>
                </h1>

                <h1 className="-text-orange-300 -text-lg -ml-1 -tracking-wider">
                  Step Two: Generate Your Plan
                </h1>
                <div
                  className={`example-text ${fade ? "fade-in" : "fade-out"} -text-lg -text-center -font-semibold -text-orange-200`}
                >
                  Example: {examples[currentExample]}
                </div>

                <form onSubmit={handleInput}>
                  <textarea
                    onKeyPress={handleKeyPress}
                    value={input}
                    onChange={onChange}
                    disabled={isLoading || !priority}
                    id="mainInput"
                    className="custom-textarea"
                    placeholder="Type here to generate a development plan and estimate in seconds with our custom software development solutions leveraging AI technologies, machine learning, and AI applications for digital health and beyond."
                  ></textarea>
                </form>
                <p className="press-enter-web --mt-2">Press Enter to submit</p>
              </div>
            </div>
          )}
          {chatResponse && !isLoading && (
            <div className="chat-response -overflow-auto -h-full -w-full ">
              <div className="-mt-4  -rounded-lg -shadow-lg">
                <h2 className="-text-3xl -text-orange-400 -font-bold  -text-center">
                  Development Plan
                </h2>
                <div className="-mb-4 -flex -justify-center --mt-7 -tracking-tighter">
                  <p className="-text-white -text-center -max-w-md -text-lg -font-semibold">
                    {chatResponse.projectOverview}
                  </p>
                </div>
                <ul className="-timeline -timeline-snap-icon max-md:-timeline-compact -timeline-vertical">
                  {chatResponse.phases.map((phase, index) => (
                    <li key={index}>
                      <div className="-timeline-middle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="orange"
                          className="-h-6 -w-6"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div
                        className={
                          index % 2 === 0
                            ? "-timeline-start -mb-10 -rounded-lg -timeline-box  -p-4 -max-w-md -bg-gray-100 -shadow-lg -shadow-orange-300"
                            : "-timeline-end -mb-10 -rounded-lg -timeline-box -p-4 -max-w-md -bg-gray-100 -shadow-lg -shadow-orange-300"
                        }
                      >
                        <time className="-font-mono -italic">
                          {phase.duration}
                        </time>
                        <div className="-text-lg -font-black">
                          {phase.title}
                        </div>
                        <p className="-break-words -whitespace-normal">
                          <strong>Activities:</strong> {phase.activities}
                        </p>
                        <p className="-break-words -whitespace-normal">
                          <strong>Resources:</strong>{" "}
                          {phase.resources.join(", ")}
                        </p>
                        <p className="-break-words -whitespace-normal">
                          <strong>Output:</strong> {phase.output}
                        </p>
                        <p className="-break-words -whitespace-normal">
                          <strong>*Estimated Cost:</strong> {phase.cost}
                        </p>
                      </div>
                      {index < chatResponse.phases.length - 1 && (
                        <hr className="-bg-orange-400" />
                      )}
                    </li>
                  ))}
                </ul>
                <h2 className="-text-3xl -text-orange-400 -font-bold  -text-center">
                  Estimated Total Cost
                </h2>
                <div className="-mb-4 -flex -justify-center --mt-7 -tracking-tighter">
                  <p className="-text-white -text-center -max-w-md -text-3xl -font-semibold -underline -underline-offset-4">
                    {` $${lowerBound.toLocaleString()} - $${upperBound.toLocaleString()}`}
                  </p>
                </div>
                <div
                  role="alert"
                  className="-alert -bg-orange-500 -text-white -w-[50vw] -mx-auto -mb-4 -font-semibold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="-stroke-white -h-6 -w-6 -shrink-0"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>

                  <span>
                    Note: This estimate (+-25%) is a general idea based on the
                    information provided. Actual costs may vary.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        {showPopup && (
          /* Popup */
          <div className="-fixed  -inset-0 -flex -items-center -justify-center -z-50 -bg-black -bg-opacity-50">
            <div className="-fixed -top-10 -bg-orange-100 -p-6 -rounded-3xl -shadow-lg -border-2 -border-black">
              <h3 className="-text-lg -font-bold -mb-2 -text-wrap ">
                Would you like MarkiTech to send you user stories based on your
                idea?
              </h3>

              <div className="-flex -justify-center -mb-4 -gap-2">
                <button
                  className="-btn -btn-md -bg-orange-500 -p-4 -mt-4 -text-white hover:-text-white hover:-bg-green-500 -border -border-gray-300"
                  onClick={closePopup}
                >
                  Yes
                </button>
                <button
                  className="-btn -btn-md -bg-gray-100 -mt-4 -p-4 -text-black hover:-text-white hover:-bg-red-800 -border -border-gray-300"
                  onClick={closePopup}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}

        <dialog id="my_modal_1" className="-modal ">
          <form
            method="dialog"
            className="-modal-box -fixed -top-10"
            onSubmit={handleEmailSubmit}
          >
            {isIframeVisible && (
              <>
                <h3 className="-font-bold -text-xl">Enter your Email</h3>
                <p>Please enter your email before proceeding.</p>

                <iframe
                  ref={iframeRef}
                  src={initialIframeSrc}
                  onLoad={handleIframeLoad}
                  className="-w-full -h-52 -border-none"
                  data-rocket-lazyload="fitvidscompatible"
                  data-lazy-src={initialIframeSrc}
                  data-ll-status="loaded"
                  title="Estimation Tool Form"
                  aria-label="Estimation Tool Form"
                ></iframe>
              </>
            )}

            {!isIframeVisible && (
              <>
                <h3 className="-font-bold -text-xl -mt-4">Re-confirm Email</h3>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="-input -input-primary -input-bordered -w-full -max-w-xs -mb-4"
                  required
                />
                <div className="-modal-action">
                  <GoogleReCaptcha
                    onVerify={handleVerify}
                    refreshReCaptcha={refreshRecaptcha}
                  />

                  <button
                    type="submit"
                    className="-btn -btn-primary -ml-4 -text-white hover:-text-white hover:-bg-green-600"
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </form>
        </dialog>
      </GoogleReCaptchaProvider>
    </>
  );
}
export default ChatLogic;
