import { Field, Formik, Form } from "formik";

const Step3 = ({ next, back, data, setFormData }) => {
  return (
    <Formik
      initialValues={{
        nonClinicalTasks: data.nonClinicalTasks || "",
        avgTimePerTask: data.avgTimePerTask || "",
        interactionToAdminRatio: data.interactionToAdminRatio || "",
      }}
      onSubmit={(values) => {
        next(values);
      }}
    >
      {({ values, handleChange }) => (
        <Form className="-space-y-2">
          <h2 className="-text-xl -font-bold -text-gray-800 -text-center">
            Time and Resource Utilization
          </h2>

          <div className="-du-form-control -w-full -max-w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">
                Staff Hours on Non-Clinical Tasks
              </span>
            </div>
            <Field
              as="textarea"
              name="nonClinicalTasks"
              className="-du-textarea -du-textarea-bordered -du-textarea-primary- -max-w-full -resize-y -max-h-48"
              placeholder="E.g., Administrative paperwork, data entry, manual records"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, nonClinicalTasks: e.target.value });
              }}
            />
          </div>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">Average Time per Task</span>
            </div>
            <Field
              as="textarea"
              name="avgTimePerTask"
              className="-du-textarea -du-textarea-bordered -du-textarea-primary- -max-w-full -resize-y -max-h-48"
              placeholder="Enter time in minutes"
              onChange={(e) => {
                handleChange(e);
                setFormData({ ...data, avgTimePerTask: e.target.value });
              }}
            />
          </div>

          <div className="-du-form-control -w-full">
            <div className="-du-label -text-md -font-bold -text-gray-700">
              <span className="-du-label-text">
                Ratio of Patient Interaction Time to Admin Work
              </span>
            </div>
            <Field
              name="interactionToAdminRatio"
              type="text"
              className="-du-input -du-input-bordered -du-input-primary"
              placeholder="E.g., 2:1"
              onChange={(e) => {
                handleChange(e);
                setFormData({
                  ...data,
                  interactionToAdminRatio: e.target.value,
                });
              }}
            />
          </div>

          <div className="-flex -justify-between -pt-4">
            <button
              type="button"
              onClick={back}
              className="-du-btn -du-btn-secondary -text-white -du-btn-sm"
            >
              PREV
            </button>

            <button
              type="submit"
              className="-du-btn -du-btn-success -text-white -du-btn-sm"
            >
              NEXT
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step3;
