const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const send_mail = async (plan) => {
  const response = await fetch(`${BACKEND_URL}/api/send-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ plan }),
  });
  console.log(response);
  if (!response.ok) {
    throw new Error("Failed to send email");
  }

  return response.json();
};
