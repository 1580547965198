const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const verify_token = async (token) => {
  const response = await fetch(`${BACKEND_URL}/api/verify-recaptcha`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: token }),
  });

  console.log("verify_token response:", response);

  return response.json();
};
